<script>
import Circle from "@/components/Circle";
import Modal from "@/components/Modal";
import axios from "axios";
export default {
  components: {
    Circle,
    Modal,
  },
  data() {
    axios
      .get(`https://dashboard.bigsisterprod.com/api/films/3`)
      .then((response) => (this.films = response.data));
    axios
      .get(`https://dashboard.bigsisterprod.com/api/books`)
      .then((response) => (this.books = response.data));
    axios
      .get(`https://dashboard.bigsisterprod.com/api/about/jihan`)
      .then((response) => (this.jihan = response.data));
    return {
      films: [],
      books: [],
      jihan: [],
      showModal: false,
    };
  },
  methods: {
    openModal(film) {
      this.showModal = true;
      this.film = film;
      document.body.classList.add("noScroll");
    },
    closeModal(film) {
      this.showModal = false;
      this.film = film;
      document.body.classList.remove("noScroll");
    },
  },
  updated() {
    //Sort Films//
    this.films.sort((a, b) => (a.year < b.year ? 1 : -1));
  },
};
</script>

<template>
  <div>
    <section class="about team_member">
      <div class="container">
        <h1 class="title" data-aos="fade-right" data-aos-duration="1000">
          Jihan <br />
          El-Tahri
        </h1>
        <div class="infoAbout">
          <div class="topSection">
            <div class="content">
              <p
                data-aos="fade-down"
                data-aos-duration="1000"
                v-html="jihan.section_one"
              ></p>
            </div>
            <figure data-aos="fade-left" data-aos-duration="1000">
              <img src="imgs/jihan_1.jpg" alt="" />
            </figure>
          </div>
          <!---->
          <div class="moreAbout">
            <figure data-aos="fade-down" data-aos-duration="1000">
              <img src="imgs/jihan_2.png" alt="" />
            </figure>
            <div class="content" data-aos="fade-down" data-aos-duration="1000">
              <p v-html="jihan.section_two"></p>
              <!---->
              <p v-html="jihan.section_three"></p>
            </div>
          </div>
        </div>
        <!---------------------------------------->
        <div>
          <h1 class="title" data-aos="fade-right" data-aos-duration="1000">
            Filmography
          </h1>
          <!--Films-->
          <ul class="work_row">
            <li
              v-for="film in films"
              :key="film"
              data-aos="fade-down"
              data-aos-duration="1000"
              @click="openModal(film)"
            >
              <h1>{{ film.title }}</h1>
              <h1>{{ film.role }}</h1>
              <h1>{{ film.year }}</h1>
            </li>
          </ul>
          <!---->
          <!--Books-->
          <h1
            class="title"
            data-aos="fade-right"
            data-aos-duration="1000"
            style="margin-top: 48px"
          >
            Books
          </h1>
          <ul class="work_row">
            <li
              v-for="work in books"
              :key="work"
              data-aos="fade-down"
              data-aos-duration="1000"
            >
              <h1>{{ work.title }}</h1>
              <h1>
                <small>Published by<br /></small>{{ work.publisher }}
              </h1>
            </li>
          </ul>
          <!---->
          <div class="arts">
            <h1 class="title">
              Visual Arts <br />
              <small>Jihan's Portfolio</small>
            </h1>
            <a href="arts.pdf" target="_new">
              <Circle>download download</Circle>
            </a>
            <!-- <div>
              <h1>Jihan's Portfolio</h1>
              <a href="arts.pdf" target="_new">
                <Circle>download download</Circle>
              </a>
            </div> -->
          </div>
        </div>
        <!---------------------------------------->
      </div>
    </section>
    <!--Modal-->
    <transition name="slide">
      <Modal v-if="showModal" @close="closeModal(film)">
        <template v-slot:body>
          <div class="vid" v-if="film.video_url && film.video_url.length > 0">
            <iframe
              :src="film.video_url"
              width="100%"
              height="100%"
              frameborder="0"
              title="trailer"
              allowfullscreen
            ></iframe>
          </div>
          <a href="#info" class="infobtn">
            <span>scroll for info</span>
            <img src="imgs/down-arrow.gif" height="25" alt="" />
          </a>
          <main class="modal__content" id="info">
            <div class="info">
              <div class="info__left">
                <h1>{{ film.title }}</h1>
                <ul>
                  <li>by {{ film.director }}</li>
                  <li>{{ film.year }}</li>
                  <li>{{ film.type }}</li>
                  <li>{{ film.length }}</li>
                </ul>
              </div>
              <div class="info__mid">
                <h3>Synopsis</h3>
                <div v-html="film.synopsis"></div>
              </div>
              <div
                class="info__right"
                v-if="film.awards && film.awards.length > 0"
              >
                <h3 style="text-align: center">Awards & Festivals</h3>
                <ul class="awardList">
                  <li v-for="award in film.awards" :key="award">
                    {{ award.name }}
                  </li>
                </ul>
              </div>
            </div>
          </main>
        </template>
      </Modal>
    </transition>
    <!--End Modal-->
  </div>
</template>
